require('./bootstrap');
import Choices from 'choices.js';

/**
 * ====================
 * Sidebar toggle
 * ====================
 */
document.getElementById("sidebarCollapse").addEventListener('click', function() {
    let sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle('active');
});

/**
 * ====================
 * Choices
 * Doc: https://github.com/Choices-js/Choices#terminology
 * Todo: search for non redundant solution...
 * ====================
 */
const choiceConfig = {
  noChoicesText: "Keine weiteren Einträge gefunden.",
  itemSelectText: "Klicken zum auswählen.",
  loadingText: 'Lädt...',
  noResultsText: 'Keine Ergebnisse gefunden.',
};
const jsChoice = document.querySelector('.js-choice');
const SecondJsChoice = document.querySelector('.js-second-choice');
if(jsChoice) { new Choices(jsChoice, choiceConfig); }
if(SecondJsChoice) { new Choices(SecondJsChoice, choiceConfig); }


/**
 * ====================
 * Open Datepicker on click into its input field
 * ====================
 */
let appointmentDate = document.getElementsByClassName("dateTimePickerAppointmentDate");
let decisionDate = document.getElementsByClassName("dateTimePickerAppointmentDecisionDate");
showDatepickerForInputElement(appointmentDate);
showDatepickerForInputElement(decisionDate);

function showDatepickerForInputElement(element) {

  if(typeof undefined !== element && element.length >= 1) {
    element = element[0];

    element.addEventListener("click", function(){
      element.showPicker();
    });
  }
}

/**
 * ====================
 * Add question and answers to appointment
 * ====================
 */
let showQuestionsString = document.getElementById("showQuestions");
let removeQuestionsString = document.getElementById("removeQuestions");
let questionAmount = document.querySelectorAll(".entry").length;

// If the "addQuestion" link is getting clicked, we provide input fields to add them
if (showQuestionsString !== null) {
    showQuestionsString.addEventListener('click', function(e) {
        e.preventDefault();
        let wrapperElement = document.getElementById("entry-wrapper");
        let questionsCounter = document.getElementById("questions-counter");
        let addQuestionBtn = document.getElementById("addQuestion");

        questionsCounter.value = 1;
        wrapperElement.classList.remove("d-none");

        // show button for adding questions
        addQuestionBtn.classList.remove("d-none");

        //remove the link for adding questions and add link to remove them
        e.target.classList.add("d-none")
        let wrapper = e.target.closest(".question-answer-wrapper");
        let removeQuestionsLink = wrapper.querySelector("#removeQuestions");
        removeQuestionsLink.classList.remove("d-none");
    });
}

/**
 * If the "removeQuestion" link is getting clicked, we remove all fields.
 * Except for first question with first answer. Those fields we just empty.
 */
if (removeQuestionsString !== null) {
    removeQuestionsString.addEventListener('click', function(e) {
        e.preventDefault();
        let wrapperElement = document.getElementById("entry-wrapper");
        let questions = wrapperElement.querySelectorAll(".entry");
        let addQuestionBtn = document.getElementById("addQuestion");

        // hide button for adding questions
        addQuestionBtn.classList.add("d-none");

        // if more than one question => remove all of them except the first one
        if(questions.length > 1) {
            for(let i = 1; i < questions.length; i++) {
                console.log(questions, "questions");
                console.log("trying to remove: ", i, questions[i]);
                questions[i].remove();
            }
        }

        let remainingQuestionEntry = questions[0];
        let remainingQuestionInput = remainingQuestionEntry.querySelector("input");
        remainingQuestionInput.value = "";

        wrapperElement.classList.add("d-none");
        updateQuestionCounterField();

        // remove all answers except the first one
        let answersWrapper = remainingQuestionEntry.querySelector(".answers-wrapper");
        let answerEntries = answersWrapper.querySelectorAll(".answer-entry");

        answerEntries.forEach (function (item, index) {
            if(index === 0) {
                return;
            }

            answersWrapper.removeChild(item);
        });

        let remainingAnswerEntry = answerEntries[0];
        let remainingAnswerInput = remainingAnswerEntry.querySelector("input");

        remainingAnswerInput.value = "";

        // remove the link for removing questions and add link to add them
        e.target.classList.add("d-none")
        let wrapper = e.target.closest(".question-answer-wrapper");
        let removeAnswersLink = wrapper.querySelector("#showQuestions");
        removeAnswersLink.classList.remove("d-none");
    });
}

// if there are more than one question, we trigger the method to add the delete icons
if(questionAmount >= 1) {
    addDeleteIcons();
}

/**
 * Adds a question input field with a default answer field beneath.
 * Also sets the correct name attributes for question and answer.
 *
 * @param e
 */
function addQuestion(e) {
    const entryWrapper = document.querySelector("#entry-wrapper");
    const entries = entryWrapper.querySelectorAll(".entry");
    const entry = entries[entries.length- 1].cloneNode(true);
    const input = entry.querySelector("input");
    const inputName = input.getAttribute("name");
    const inputNumber = parseInt(inputName.match(/\d+/)[0], 10) + 1;
    let questionsCounter = document.getElementById("questions-counter");

    // Reset the question input field
    input.setAttribute("name", `questions-${inputNumber}`);
    input.value = "";

    // Remove all answer input fields except for the first one
    const answersWrapper = entry.querySelector(".answers-wrapper");
    const answerEntries = answersWrapper.querySelectorAll(".answer-entry");
    for (let i = 1; i < answerEntries.length; i++) {
        answersWrapper.removeChild(answerEntries[i]);
    }

    // Set the class of the button based on the question count
    const addQuestion = entry.querySelector(".add-question-btn");
    if(addQuestion) {
        const addQuestionBtn = addQuestion.querySelector("button");
        const addQuestionBtnSpan = entry.querySelector(".add-question-btn button span");
        if (inputNumber > 0) {
            if(addQuestionBtn){
                addQuestion.classList.remove("d-none");
                addQuestionBtn.classList.remove("btn-primary");
                addQuestionBtn.classList.add("btn-danger");
            }
          
            if(addQuestionBtnSpan) {
                addQuestionBtnSpan.textContent = "close";
                addQuestionBtnSpan.classList.add("text-white");
            }
        }
    }

    // set correct answer name attribute
    answerEntries[0].querySelector("input").setAttribute("name", `answers-${inputNumber}-1`);
    answerEntries[0].querySelector("input").value = "";

    entryWrapper.appendChild(entry);
    questionsCounter.value = parseInt(questionsCounter.value) + 1;
}

/**
 * Adds a answer input field to the related question.
 * The answer will also get the correct name attribute and Button after the input.
 *
 * @param e
 */
function addAnswer(e) {
    const answerEntries = e.target.closest(".entry").querySelectorAll(".answers-wrapper .answer-entry");
    const answerEntry = answerEntries[answerEntries.length - 1].cloneNode(true);
    const input = answerEntry.querySelector("input");
    const inputName = input.getAttribute("name");
    const questionInput = e.target.closest(".entry").querySelector(".question-wrapper input");
    const questionInputName = questionInput.getAttribute("name");
    const questionNumber = parseInt(questionInputName.split("-")[1], 10);
    const answerNumber = parseInt(inputName.split("-")[2], 10) + 1;

    input.setAttribute("name", `answers-${questionNumber}-${answerNumber}`);
    input.value = "";
    e.target.closest(".entry").querySelector(".answers-wrapper").appendChild(answerEntry);

    const addAnswerBtn = answerEntry.querySelector(".add-answer-btn button");
    const addAnswerBtnSpan = answerEntry.querySelector(".add-answer-btn button span");

    if (answerNumber > 0) {
        addAnswerBtn.classList.remove("btn-primary");
        addAnswerBtn.classList.add("btn-danger");

        addAnswerBtnSpan.textContent = "close";
        addAnswerBtnSpan.classList.add("text-white");
    }

    // Remove the event listener from the "Add Answer" button
    e.target.removeEventListener("click", addAnswer);
}

/**
 * Removes question
 *
 * @param e
 */
function removeQuestion(e) {
    const element = e.target.closest(".entry");
    element.parentNode.removeChild(element);

    synchQuestionNameAttributes();
    updateQuestionCounterField();
    reorderAllAnswerNameAttributes();
}

/**
 * Removes answer
 *
 * @param e
 */
function removeAnswer(e) {
    const element = e.target.closest(".answer-entry");
    const answersWrapper = element.parentNode;
    answersWrapper.removeChild(element);

    synchAnswerNameAttributes(answersWrapper);
}

/**
 * After we have removed some questions we need to adjust the counter for the hidden field.
 * This field is telling the backend later on how often to loop through the request object.
 * Its a must since we have dynamically input here and there could be X Questions and Y answers.
 */
function updateQuestionCounterField() {
    let wrapperElement = document.getElementById("entry-wrapper");
    let questions = wrapperElement.querySelectorAll(".entry");
    let questionsCounter = document.getElementById("questions-counter");

    questionsCounter.setAttribute("value", questions.length);
}

/**
 * After deleting questions we need to reorder the correct name attributes so the 3rd question is getting
 * the counter of 2 if the 2nd questions was deleted.
 */
function synchQuestionNameAttributes() {
    const allQuestions = document.querySelectorAll(".entry-wrapper .entry");
    let counter = 0;

    for(let i = 1; i <= allQuestions.length; i++) {
        allQuestions[counter].querySelector("input").setAttribute("name", `questions-${i}`);
        counter++;
    }
}

/**
 * After removing some answers we need to reorder the correct name attributes so the 3rd answer is getting
 * the counter of 2 if the 2nd answer was deleted.
 *
 * @param answersWrapper
 */
function synchAnswerNameAttributes(answersWrapper) {
    const questionInput = answersWrapper.closest(".entry").querySelector(".question-wrapper input");
    const questionInputName = questionInput.getAttribute("name");
    const questionNumber = parseInt(questionInputName.split("-")[1], 10);
    const allAnswers = answersWrapper.querySelectorAll(".answer-entry");

    let counter = 0;

    for(let i = 1; i <= allAnswers.length; i++) {
        allAnswers[counter].querySelector("input").setAttribute("name", `answers-${questionNumber}-${i}`);
        counter++;
    }
}

/**
 * Reorders the name attributes of all answers
 * Todo: Combine this and the methode above since they are doing basically the same. This method covers all answers and the method above is covering only the siblings of the removed answer.
 */
function reorderAllAnswerNameAttributes() {
    const entryWrapper = document.querySelector("#entry-wrapper");
    const entries = entryWrapper.querySelectorAll(".entry");

    entries.forEach (function (item) {
        const question = item.querySelector("input");
        const questionInputName = question.getAttribute("name");
        const questionNumber = parseInt(questionInputName.split("-")[1], 10);
        const answers = item.querySelectorAll(".answers-wrapper .answer-entry");

        answers.forEach(function(answerItem, answerIndex) {
            let counter = answerIndex + 1;
            answerItem.querySelector("input").setAttribute("name", 'answers-' + questionNumber + '-' + counter);
        });
    });
}

/**
 * Adds delete icons to the questions and answers which are not the first one since the first question and first answer
 * of every questions keeps the plus-icon to add more of them.
 */
function addDeleteIcons() {
    let questionInputs = document.querySelectorAll(".entry .question-wrapper input");

    // add delete icon to questions - except the first one
    // for (let i = 1; i < questionInputs.length; i++){
    //     let inputField = questionInputs[i];
    //     let button = inputField.closest(".question-wrapper").querySelector(".add-question-btn > .btn-primary");
    //     let buttonSpan = button.querySelector("span");

    //     button.classList.remove("btn-primary");
    //     button.classList.add("btn-danger");

    //     buttonSpan.textContent = "close";
    //     buttonSpan.classList.add("text-white");
    // }

    // for answers we take a new loop since here we need to access the first question
    for (let i = 0; i < questionInputs.length; i++){
        let inputField = questionInputs[i];
        let answerInputFields = inputField.closest(".entry").querySelectorAll(".answer-entry input");

        // loop answers
        for (let j = 1; j < answerInputFields.length; j++){

            let inputField = answerInputFields[j];
            let button = inputField.closest(".answer-entry").querySelector(".add-answer-btn > .btn-primary");
            let buttonSpan = button.querySelector("span");

            button.classList.remove("btn-primary");
            button.classList.add("btn-danger");

            buttonSpan.textContent = "close";
            buttonSpan.classList.add("text-white");
        }
    }
}

/**
 * Event listeners
 */

const addQuestionBtn = document.querySelector("#addQuestion");

if(addQuestionBtn) {
    addQuestionBtn.addEventListener("click", function(e){
        addQuestion(e);
    });
}

const entryWrapper = document.querySelector("#entry-wrapper");

if(entryWrapper !== null) {
    entryWrapper.addEventListener("click", function(e) {
        if (e.target.matches(".add-answer-btn .btn-primary") || e.target.matches(".add-answer-btn .btn-primary span")) {
            addAnswer(e);
        } else if (e.target.matches(".add-question-btn .btn-danger") || e.target.matches(".add-question-btn .btn-danger span")) {
            removeQuestion(e);
        } else if (e.target.matches(".add-answer-btn .btn-danger") || e.target.matches(".add-answer-btn .btn-danger span")) {
            removeAnswer(e);
        }
    });
}

const appointmentMembersViewBtn = document.querySelector("#toggleMembersView");

if (appointmentMembersViewBtn) {
    appointmentMembersViewBtn.addEventListener("click", function() {
        document.querySelectorAll(".member-view-toggle-element").forEach(function (el) {
            el.classList.toggle("d-none");
        });
    });
}

/**
 * Delete confirm
 */

document.querySelectorAll(".form-delete").forEach (function (deleteForm) {
    deleteForm.addEventListener('submit', function(event) {
        if(!confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
            event.preventDefault();
            return false;
        }
    });
});